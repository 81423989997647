<template>
  <b-card>
    <b-row>
      <b-col cols="12">
        Filtrer:
      </b-col>
      <b-col cols="12">
        <div :class="{'row-filter': row}">
          <b-form-group
            v-for="filter in filtersOptions"
            :key="filter.id"
            :label="filter.label"
          >
            <b-form-checkbox-group
              :checked="currentFilters[filter.id]"
              stacked
              @change="v => updateFilter(filter.id, v)"
            >
              <b-form-checkbox
                v-for="option in filter.options"
                :key="option.value"
                :value="option.value"
                :disabled="currentFilters[filter.id].length === 1 && currentFilters[filter.id].indexOf(option.value) !== -1"
              >
                {{ option.text }}
              </b-form-checkbox>
            </b-form-checkbox-group>
          </b-form-group>
        </div>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard, BRow, BCol, BFormGroup, BFormCheckboxGroup, BFormCheckbox
} from "bootstrap-vue";
import { mapState } from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormCheckboxGroup,
    BFormCheckbox
  },
  props: {
    row: {
      type: Boolean,
      default: false
    }
  },
  computed: mapState("iaa", ["currentFilters", "filtersOptions"]),
  methods: {
    updateFilter(id, value) {
      this.$store.commit("iaa/SET_IAA_FILTER", { id, value });
    }
  }
};
</script>

<style lang="scss" scoped>
.row-filter {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
