<template>
  <b-card
    v-if="isShown"
    title="Nombre d’exploitations par valeur moyenne"
  >
    <e-charts
      style="width: 100%; height: 90%;"
      :options="chartData"
      theme="theme-color"
    />
  </b-card>
</template>

<script>
import { getUserData } from "@/auth/utils";
import ECharts from "vue-echarts";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/legend";
import "echarts/lib/chart/bar";
import theme from "@core/components/charts/echart/theme.json";
import { mapState } from "vuex";
import { BCard } from "bootstrap-vue";

ECharts.registerTheme("theme-color", theme);

export default {
  name: "DistributionChart",
  components: {
    ECharts,
    BCard
  },
  props: {
    indicator: {
      type: Object,
      default: () => ({})
    }
  },
  data() {
    return {
      userData: getUserData(),
      measures: [],
      chartData: {
        grid: {
          top: 12,
          left: 40,
          right: 0,
          bottom: 48
        },
        legend: {
          left: 0
        },
        yAxis: [
          {
            type: "value",
            minInterval: 1,
            splitLine: { show: false },
            axisLabel: {
              formatter: "{value}"
            }
          }
        ]
      },
      isShown: false
    };
  },
  computed: {
    ...mapState("verticalMenu", ["currentPeriod"]),
    minIndicatorsValues() {
      return Math.floor(Math.min(...this.indicator.measures));
    },
    maxIndicatorsValues() {
      return Math.ceil(Math.max(...this.indicator.measures));
    }
  },
  watch: {
    indicator() {
      this.formatChartData(10);
    },
    currentPeriod() {
      this.formatChartData(10);
    }
  },
  created() {
    this.formatChartData(10);
  },
  methods: {
    formatChartData(intervalsCount) {
      const category = []; /* Nom des catégories de xAxis echarts 30/32% 32/34% */
      const series = []; /* Données echarts de type [[x, y],[x2, y2]], x = l'index de la category, y = la valeur en ordonnée */

      const unit = this.indicator.unit === "%" ? "%" : "";

      /* Calcule l'écart entre chaque catégorie de valeur */
      const intervalsWidth = (this.maxIndicatorsValues - this.minIndicatorsValues) / intervalsCount;
      for (let i = 0; i < intervalsCount; i += 1) {
        /* Détermine le minimum et le maximum pour chaque category */
        const lowerBound = Number((this.minIndicatorsValues + intervalsWidth * i).toFixed(1));
        const upperBound = Number((lowerBound + intervalsWidth).toFixed(1));

        // on récupère les valeurs qui correspondent à l'interval
        const currentValues = this.indicator.measures.filter(value => {
          const isMeasureMax = Number(value) === upperBound && i === intervalsCount - 1;
          const isMeasureContained = value >= lowerBound && value < upperBound;
          return isMeasureMax || isMeasureContained;
        });

        // on calcule la moyenne des valeurs de l'interval
        const averageMeasure = currentValues.length === 0 ? 0 : currentValues.reduce((acc, measure) => acc + parseFloat(measure), 0) / currentValues.length;

        category.push(Math.round(averageMeasure * 100) / 100);

        /* Construis le tableau attendu par la series echarts et assigne une couleur différente pour la ferme actuelle. */
        if (currentValues.length > 0) {
          series.push({
            value: [i, currentValues.length],
            itemStyle: {
              color: "#9aece3"
            }
          });
        }
      }
      /* Construis l'option attendu par echarts */
      this.chartData = {
        ...this.chartData,
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "shadow"
          },
          formatter: params => (
            `${params[0].name}${unit}<br/><span style='display:inline-block;width:10px;height:10px;background-color:${params[0].color};margin-right:5px;'></span>${params[0].value[1]}`
          )
        },
        xAxis: [
          {
            type: "category",
            data: category, // Ajout des données formatées.
            axisLabel: {
              interval: 0,
              rotate: 30,
              formatter(value, index) {
                return value !== "0" || !index ? `${value}${unit}` : "";
              }
            }
          }
        ],
        series: [
          {
            type: "bar",
            barMaxWidth: "20%",
            barMinWidth: "20px",
            data: series, // Ajout des données formatées.
            itemStyle: {
              barBorderRadius: [15, 15, 0, 0]
            }
          }
        ]
      };
      this.isShown = true;
    }
  }
};
</script>
